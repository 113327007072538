export enum RESOURCE_TYPE {
	/** 课程大纲 */
	SYLLABUS = 'syllabus',
	/** 课程电子书 */
	EBOOK = 'ebook',
	/** 课程笔记 */
	SUMMARY = 'summary',
	/** 大学课程的公开资料 */
	UNIVERSITY_COURSE = 'university_course',
	/** 考试相关资料 */
	EXAM = 'exam',
	/** Teacher 简历 */
	RESUME = 'resume',
	/** Teacher Onboading资料 */
	TEACHER = 'teacher',
	/** Tutor Onboarding资料 */
	TUTOR = 'tutor',
	TRANSCRIPT = 'transcript',
	NOTE = 'topNote',
	/** 简历模板 */
	RESUME_TEMPLATE = 'resume_template',
	/** 面试真题 */
	INTERVIEW_QUESTIONS = 'interview_questions',
	DAYOFF = 'dayoff_attachment',
	COVER_LETTER = 'cover_letter',
	OTHER = 'other'
}
export const RESOURCE_TYPE_TEXT = {
	[RESOURCE_TYPE.SYLLABUS]: '课程大纲',
	[RESOURCE_TYPE.EBOOK]: '电子书',
	[RESOURCE_TYPE.SUMMARY]: '学习笔记',
	[RESOURCE_TYPE.UNIVERSITY_COURSE]: '大学课程资料',
	[RESOURCE_TYPE.EXAM]: '备考资料',
	[RESOURCE_TYPE.RESUME]: '简历模板',
	[RESOURCE_TYPE.TEACHER]: 'Teacher Onboarding资料',
	[RESOURCE_TYPE.TUTOR]: 'Tutor Onboarding资料',
	[RESOURCE_TYPE.TRANSCRIPT]: '大学成绩单',
	[RESOURCE_TYPE.NOTE]: '笔记',
	[RESOURCE_TYPE.RESUME_TEMPLATE]: '简历模板',
	[RESOURCE_TYPE.INTERVIEW_QUESTIONS]: '面试真题',
	[RESOURCE_TYPE.DAYOFF]: 'Dayoff Attachment',
	[RESOURCE_TYPE.COVER_LETTER]: 'CoverLetter',
	[RESOURCE_TYPE.OTHER]: '其他'
};

export enum EPublicResourceType {
	/** 课程笔记 */
	SUMMARY = 'summary',
	/** 大学课程的公开资料 */
	UNIVERSITY_COURSE = 'university_course',
	/** 考试相关资料 */
	EXAM = 'exam'
}

export const PUBLIC_RESOURCE_TYPE_TEXT = {
	[RESOURCE_TYPE.SUMMARY]: '课程笔记',
	[RESOURCE_TYPE.UNIVERSITY_COURSE]: '课程资料',
	[RESOURCE_TYPE.EXAM]: '备考资料'
};

// Course Resource type
export enum COURSE_RESOURCE_TYPE {
	SYLLABUS = 'syllabus', // 课程大纲
	EXAM = 'exam', // 备考资料
	RESUME = 'resume', // 简历模板
	BOOK = 'book', // 电子书
	INTERVIEW_QUESTIONS = 'jobInterviewQuestions', // 面试真题
	JOB_INTERVIEW = 'jobInterview', // 面试资料
	LESSON_NOTE = 'lessonNote', // 学习笔记
	PROFESSIONS = 'professions', // 行业资料库
	GRADUATES = 'graduates', // 校招汇总
	CHEAT_SHEET = 'Cheatsheet', // Cheatsheet
	CERTIFICATE = 'Certificate', // 技术证书学习材料
	LEARNING = 'learning' // 学习资料
}

export const COURSE_RESOURCE_TYPE_TEXT = {
	[COURSE_RESOURCE_TYPE.SYLLABUS]: '课程大纲',
	[COURSE_RESOURCE_TYPE.EXAM]: '备考资料',
	[COURSE_RESOURCE_TYPE.RESUME]: '简历模板',
	[COURSE_RESOURCE_TYPE.BOOK]: '电子书',
	[COURSE_RESOURCE_TYPE.INTERVIEW_QUESTIONS]: '面试真题',
	[COURSE_RESOURCE_TYPE.JOB_INTERVIEW]: '面试资料',
	[COURSE_RESOURCE_TYPE.LESSON_NOTE]: '学习笔记',
	[COURSE_RESOURCE_TYPE.PROFESSIONS]: '行业资料库',
	[COURSE_RESOURCE_TYPE.GRADUATES]: '校招汇总',
	[COURSE_RESOURCE_TYPE.CHEAT_SHEET]: 'Cheatsheet',
	[COURSE_RESOURCE_TYPE.CERTIFICATE]: '技术证书学习材料',
	[COURSE_RESOURCE_TYPE.LEARNING]: '学习资料'
};

// file type
export enum FILE_TYPE {
	doc = 'application/msword',
	docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	pdf = 'application/pdf',
	xzip = 'application/x-zip-compressed',
	xrar = 'application/x-rar-compressed',
	ppt = 'application/vnd.ms-powerpoint',
	pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	jpg = 'image/jpeg',
	png = 'image/png',
	mp4 = 'video/mp4',
	mp3 = 'audio/mpeg',
	m4a = 'audio/m4a',
	rar = 'application/x-rar',
	zip = 'application/zip',
	rarx = 'application/x-compressed',
	xls = 'application/vnd.ms-excel', // Excel 97-2003 工作簿
	xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel 工作簿
	xltx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.template', // Excel 模板
	xlam = 'application/vnd.ms-excel.addin.macroEnabled.12', // Excel 加载宏的加载项
	xlsm = 'application/vnd.ms-excel.sheet.macroEnabled.12', // Excel 启用宏的工作簿
	xltm = 'application/vnd.ms-excel.template.macroEnabled.12' // Excel 启用宏的模板
}

// material type
export enum MATERIAL_TYPE {
	SYLLABUS = 'syllabus', // 课程大纲
	ASSIGNMENT = 'assignment', // Assignemnt要求（导师用）
	ASSIGNMENT_SUBMISSION = 'submission', // Assignemnt提交 （学生用）
	PPT = 'PPT', // 某个lesson的PPT资料 (both workshop & program)
	UNIVERSITY_WORKSHOP = 'university_workshop', // 辅导班资料
	SUMMARY = 'summary', // 学习笔记
	EXAM = 'exam', // 考试相关资料
	LESSON_RELATED_MATERIAL = 'related', // 某个lesson的相关资料 (both workshop & program)
	GLOBAL_COURSE_MATERIAL = 'global_course_material', // 全局资料
	VIDEO = 'video',
	OTHER = 'other',
	NOTE = 'topNote',
	FEEDBACK = 'feedback',
	JOB_INTERVIEW = 'jobInterview', // 面试机经附件
	LESSON_NOTE = 'lessonNote', // 随堂笔记文档资料
	STUDY_TEAM_RESOURCE = 'studyTeamResource' // p3小组资料
}

export default {};
