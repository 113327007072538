/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { isNumber } from 'lodash';
import queryString from 'query-string';

import { checkCSR } from './check';
import { getFingerPrint } from './getFingerPrint';
import { isUrl } from './validator';
import { DEVELOPMENT_API_URL, PRODUCTION_API_URL, UAT_API_URL } from '@/constants/apiUrl';
import {
	JR_IMAGE_BASE_URL,
	JR_S3_IMAGE_REG_FIRST,
	JR_S3_IMAGE_REG_SECOND
} from '@/constants/common';
import { PRODUCTION, UAT } from '@/constants/env';
import { TEMP_TOKEN, TOKEN } from '@/constants/storeLocation';
import { EUnauthorizedErrorMessage } from '@/constants/user';

export const getApiUrl = () => {
	const environment = process.env.NODE_ENV;
	if (environment === PRODUCTION) {
		if (process.env.NEXT_PUBLIC_NEXT_ENV === UAT) return UAT_API_URL;
		return PRODUCTION_API_URL;
	}
	return DEVELOPMENT_API_URL;
};

export enum HTTP_METHOD {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DELETE = 'DELETE'
}

const baseURL = getApiUrl();

export const requestBlob = async (url: string) => {
	// get fingerprint id
	const fingerprintId = await getFingerPrint();
	const res = await fetch(url, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
			Authorization: (checkCSR() && `Bearer ${localStorage.getItem(TOKEN)}`) || '',
			// if the url is a third-party url, do not set the locale to zh-CN
			...(isUrl(url) ? {} : { locale: 'zh-CN' }),
			...(!isUrl(url) && fingerprintId ? { 'x-device-id': fingerprintId } : {})
		}
	});
	return res;
};

export default async function request<T>(
	method: HTTP_METHOD,
	url: string,
	options?: {
		body?: any;
		query?: any;
		cache?: RequestCache;
		token?: string;
		next?: { revalidate: number };
		headers?: Record<string, string>;
	}
): Promise<T> {
	try {
		const queryStr = queryString.stringify(options?.query);
		const requestBaseURL = isUrl(url) ? url : baseURL + url;
		const fullUrl = queryStr ? `${requestBaseURL}?${queryStr}` : requestBaseURL;
		// get fingerprint id
		const fingerprintId = await getFingerPrint();

		const response = await fetch(fullUrl, {
			method,
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				Authorization: `Bearer ${
					options?.token ||
					(checkCSR() &&
						(localStorage.getItem(TOKEN) || sessionStorage.getItem(TEMP_TOKEN))) ||
					''
				}`,
				// if the url is a third-party url, do not set the locale to zh-CN
				...(isUrl(url) ? {} : { locale: 'zh-CN' }),
				...(!isUrl(url) && fingerprintId ? { 'x-device-id': fingerprintId } : {}),
				...(options?.headers || {})
			},
			body: JSON.stringify(options?.body),
			...(options?.cache ? { cache: options.cache } : {}),
			...(options?.next || {})
		});

		let data = await response.json();

		if (isNumber(data.statusCode) || isNumber(data.status)) {
			if (data.statusCode === 401 || data.status === 401) {
				if (
					checkCSR() &&
					(data.message === EUnauthorizedErrorMessage.INVALID_USER_SESSION ||
						data.message === EUnauthorizedErrorMessage.TOKEN_EXPIRED)
				) {
					localStorage.removeItem(TOKEN);
					document.cookie = `${TOKEN}=; path=/;`;
					window.location.reload();
				}
				throw new Error(data.message || data.statusCode);
			}
			if (data.statusCode === 404) {
				throw new Error(data.message || data.statusCode);
			}
			if (data.statusCode !== 200 && data.status !== 200) {
				console.error(
					`请求error, url:${url}, status:${data.statusCode || data.status}, message:${
						data.message
					}`
				);
				return Promise.reject(data.message);
			}
		}
		if (data.token && checkCSR()) {
			localStorage.setItem(TOKEN, data.token);
			document.cookie = `${TOKEN}=${data.token};path=/;`;
		}
		// 处理图片域名
		const dataStr = JSON.stringify(data);
		data = JSON.parse(
			dataStr
				.replace(JR_S3_IMAGE_REG_FIRST, JR_IMAGE_BASE_URL)
				.replace(JR_S3_IMAGE_REG_SECOND, JR_IMAGE_BASE_URL)
		);
		return data as T;
	} catch (e) {
		console.error(e);
		if (checkCSR()) {
			return Promise.reject(e);
		}
		return undefined as T;
	}
}
