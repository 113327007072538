export enum UserRole {
	STUDENT = 'student',
	TEACHER = 'teacher',
	STAFF = 'staff',
	ADMIN = 'admin'
}

export enum Gender {
	MALE = 'male',
	FEMALE = 'female',
	ELSE = 'else'
}

export enum WORK_EXPERIENCE {
	LESS_THAN_ONE_YEAR = '0',
	ONE_TO_THREE_YEARS = '1',
	THREE_TO_FIVE_YEARS = '2',
	FIVE_TO_TEN_YEARS = '3',
	MORE_THAN_TEN_YEARS = '4'
}

export const WORK_EXPERIENCE_OPTIONS = {
	[WORK_EXPERIENCE.LESS_THAN_ONE_YEAR]: '1年以下',
	[WORK_EXPERIENCE.ONE_TO_THREE_YEARS]: '1-3年',
	[WORK_EXPERIENCE.THREE_TO_FIVE_YEARS]: '3-5年',
	[WORK_EXPERIENCE.FIVE_TO_TEN_YEARS]: '5-10年',
	[WORK_EXPERIENCE.MORE_THAN_TEN_YEARS]: '10年以上'
};

export enum USER_ACTION {
	SIGN_IN = 'sign-in',
	SIGN_UP = 'sign-up',
	SUCCESS = 'success',
	ERROR = 'error',
	/** 发送忘记密码邮件 */
	FORGET = 'forget',
	RESET = 'reset-password',
	WECHAT = 'wechat',
	EMAIL_VERIFICATION = 'email-verification',
	EMAIL_BINDING = 'email-binding',
	BASE_INFORMATION = 'base-information'
}

export enum EUnauthorizedErrorMessage {
	INVALID_USER_SESSION = 'Invalid user session',
	TOKEN_EXPIRED = 'Token expired'
}

export enum UserSource {
	JOBPIN = 'jobpin',
	JR_ACADEMY = 'jrAcademy',
	JIANGREN = 'jiangren',
	JR_CALENDAR = 'jrCalendar'
}

/**
 * 当前状态
 */
export const USER_CURRENT_STATUS = {
	CURRENTLY: '在读',
	GRADUATED: '已毕业',
	// FRESHMAN: '新生入学',
	WORKING: '在职人士'
	// MENTOR: '导师',
	// TUTOR: '辅导老师'
};
export const USER_HOW_TO_KNOW = {
	SEARCH_ENGIN: '搜索引擎',
	LINKEDIN: 'Linkedin',
	FRIEND_RECOMMEND: '朋友推荐',
	ONLINE_ADVERTISING: '在线广告',
	COURSE_PROMOTION_ACTIVITIES: '课程推广活动',
	RECOMMEND_BY_EDU: '学校或教育机构推荐',
	JOB_WEBSITE: '职业介绍所或招聘网站',
	WECHAT_ARTICLES: '微信公众号文章',
	RED_BOOK: '小红书',
	SOCIAL_ACTIVITIES: '社交活动'
};

// User Preference
export enum EUserPreferenceTargetList {
	ARCHIVED_ITEMS = 'archivedItems',
	LIKE_ITEMS = 'likeItems'
}

export const GET_USER_PREFERENCE_ENDPOINTS = {
	GET_LIKES_OR_ARCHIVED_LIST: 'getLikesOrArchivedList',
	GET_LIKES_QUANTITY: 'getLikesQuantity',
	GET_PREFERENCE_LIST: 'getPreferenceList'
};

export const ANONYMOUS_AVATAR = '/image/avatar/user-avatar--small.svg';

export enum Country {
	AUSTRALIA = 'Australia',
	CHINA = 'China',
	SINGAPORE = 'Singapore',
	ELSE = 'else'
}

export enum CurrentStatus {
	UNIVERSITY_STUDENT = '在读',
	NEW_STUDENT = '新生入学',
	GRADUATED = '已毕业',
	WORKING = '在职人士'
}

export const USER_STATUS_QUESTION_ELSE_OPTION = '其他';

export const userStatusQuestions = [
	{
		index: 1,
		title: '上课规划',
		options: [
			'按时参与每周的直播课程',
			'根据自身情况针对性择直播课程参与上课时间',
			'与工作或者学校课程冲突，会及时看recording课程',
			USER_STATUS_QUESTION_ELSE_OPTION
		]
	},
	{
		index: 2,
		title: '培训目的',
		options: ['职业转型', '提升技能', '找到第一份工作', USER_STATUS_QUESTION_ELSE_OPTION]
	}
];

export enum UserSelectableSkill {
	WEB_DEVELOPMENT = 'Web Development',
	JAVA_SCRIPT = 'JavaScript',
	REACT = 'React',
	PYTHON = 'Python',
	AI = 'AI',
	DEVOPS = 'DevOps',
	OPEN_SOURCE = 'Open Source',
	AWS = 'AWS',
	CSS = 'CSS',
	TYPE_SCRIPT = 'TypeScript',
	JAVA = 'Java',
	DATABASE = 'Database'
}

export const DEFAULT_AVATAR = '/image/account/account-default-avatar.webp';

export default {};
