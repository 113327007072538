import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);

// Get the percentage of the duration of the program
export const getDurationPercentage = (startDate: string, endDate: string) => {
	switch (true) {
		case dayjs().isBefore(dayjs(startDate)):
			return 0;
		case dayjs().isAfter(dayjs(endDate)):
			return 100;
		default:
			return Math.round(
				(dayjs().diff(startDate, 'day') / dayjs(endDate).diff(startDate, 'day')) * 100
			);
	}
};

/**
 * YYYY/MM/DD
 */
export const getTimeFormatDate = (time: Date | string): string => {
	return dayjs(time).format('YYYY/MM/DD');
};

// Get the time with timezone
export const getTimeWithTimezone = (time: string, format = 'YYYY/MM/DD HH:mm') => {
	const timeZone = dayjs.tz.guess();
	const timeZoneCityName = timeZone?.split('/')[1] || 'Sydney';
	return `${dayjs(time).tz(timeZone).format(format)} (${timeZoneCityName})`;
};
export const getTimeWithSelectTimeZone = (
	time: string | Date,
	timeZone: string,
	format = 'YYYY/MM/DD HH:mm'
) => {
	const timeZoneCityName = timeZone?.split('/')[1] || 'Sydney';
	return `${dayjs(time).tz(timeZone).format(format)} (${timeZoneCityName})`;
};

export const getCurTimeZone = () => {
	return dayjs.tz.guess();
};
export const getTimeZoneWithOffset = (timeZone?: string) => {
	const now = dayjs().tz(timeZone);
	const offset = now.utcOffset();
	const gmtOffset = `GMT${offset > 0 ? '+' : '-'}${String(offset / 60).padStart(2, '0')}`;
	return `(${gmtOffset}) ${timeZone}`;
};

export const getTimeFromNow = (time: string): string => {
	return dayjs(time).fromNow();
};

export const getTimeFromNowInCN = (date: string) => {
	return dayjs(date).locale('zh-cn').fromNow(); // Use Chinese locale
};

export const getUTCTime = (time: string, format = 'YYYYMMDDTHHmmssSSSZ'): string => {
	const timeZone = dayjs.tz.guess();
	const formattedDateString = dayjs(time).tz(timeZone).format(format);
	return formattedDateString;
};

/**
 * Transform the duration to HH:MM:SS format
 * @param inputDuration - The duration in seconds as a string
 * @returns The duration in HH:MM:SS format
 */

export const formatDuration = (inputDuration: string = '0'): string => {
	const totalSeconds = parseFloat(inputDuration);

	const hours = Math.floor(totalSeconds / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const seconds = Math.floor(totalSeconds % 60);

	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
		.toString()
		.padStart(2, '0')}`;
};

export const formatDurationDHM = (durationSecond: number): string => {
	const days = Math.floor(durationSecond / (3600 * 24));
	const hours = Math.floor((durationSecond % (3600 * 24)) / 3600);
	const minutes = Math.floor((durationSecond % 3600) / 60);
	return `${days ? `${days}d` : ''}${hours ? `${hours}h` : ''}${minutes ? `${minutes}m` : ''}`;
};

/**
 * 将秒数转换为小时数，非整数保留一个小数位
 * @param seconds - 秒数
 * @returns 小时数，保留一个小数位
 */
export const convertSecondsToHours = (seconds: number): string => {
	const hours = (seconds / 3600).toFixed(1);
	return hours;
};

export default getDurationPercentage;
